//import { Home } from "@mui/icons-material";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router";

import "./App.css";

const QuizPage = React.lazy(() => import("./Pages/QuizPage/QuizPage"));
const PreCheckout = React.lazy(() => import("./Pages/Prepayment/Prepayment"));
const Checkout = React.lazy(() => import("./Pages/Checkout/Checkout"));
const Login = React.lazy(() => import("./Pages/Login/login"));
const CinfirmPay = React.lazy(() => import("./Pages/ConfirmPay/login"));
const About = React.lazy(() => import("./Pages/About/About"));
const Plan = React.lazy(() => import("./Pages/Plan/Plan"));
const Home = React.lazy(() => import("./Pages/Home/Home"));

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/preCheckout"
          element={
            <Suspense>
              <PreCheckout />
            </Suspense>
          }
        />
        <Route
          path="/checkout"
          element={
            <Suspense>
              <Checkout />
            </Suspense>
          }
        />
        <Route
          path="/signIn"
          element={
            <Suspense>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/confirmPay"
          element={
            <Suspense>
              <CinfirmPay />
            </Suspense>
          }
        />
        {/* <Route
          path="/"
          exact
          element={
            <Suspense>
              <QuizPage />
            </Suspense>
          }
        /> */}
        <Route
          path="/"
          element={
            <Suspense>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/plan"
          element={
            <Suspense>
              <Plan />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
